import Clarity from '@microsoft/clarity'

export default defineNuxtPlugin(() => {
  try {
    // Staging or production only
    if (
      import.meta.dev ||
      !import.meta.client
    ) {
      return
    }

    const config = useRuntimeConfig()
    const env = config.public

    if (!env.CLARITY_PROJECT_ID) {
      return
    }

    Clarity.init(
      String(env.CLARITY_PROJECT_ID)
    )
  } catch (err) {
    const error = err as Error
    console.error('[plugins::clarity] Error', error)
  }
})
