import revive_payload_client_9is72cvoEi from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2E09VCcsXp from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DNFPVfCpR2 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_w0eEW3fyeS from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt-site-config@3.1.0_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_aO5SNX0fjF from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pwUyOqK7nX from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_C4Fc65YSRs from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_E4lj6Uig0C from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/bgrand/Documents/Projects/live-display/public/.nuxt/components.plugin.mjs";
import prefetch_client_QaSDv7aU05 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import firebase_MrRCoKgj2U from "/home/bgrand/Documents/Projects/live-display/shared/plugins/firebase.ts";
import airbrake_H5MbEqRsNX from "/home/bgrand/Documents/Projects/live-display/shared/plugins/airbrake.ts";
import clarity_FoYTnyX1Oc from "/home/bgrand/Documents/Projects/live-display/public/plugins/clarity.ts";
export default [
  revive_payload_client_9is72cvoEi,
  unhead_2E09VCcsXp,
  router_DNFPVfCpR2,
  _0_siteConfig_w0eEW3fyeS,
  payload_client_aO5SNX0fjF,
  navigation_repaint_client_pwUyOqK7nX,
  check_outdated_build_client_C4Fc65YSRs,
  chunk_reload_client_E4lj6Uig0C,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QaSDv7aU05,
  firebase_MrRCoKgj2U,
  airbrake_H5MbEqRsNX,
  clarity_FoYTnyX1Oc
]