import routerOptions0 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/pages/runtime/router.options.js";
const configRouterOptions = {
  strict: true,
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = false
export default {
...configRouterOptions,
...routerOptions0,
}